import download from '@/utils/files/downloadFile';

export default async function exportData(dispatch, dispatchPath, payload, fileName, exportKey = true) {
    if (exportKey) {
        payload.export = payload.export ? payload.export : true;
    }

    await dispatch(dispatchPath, payload).then((response) => {
        return download(fileName, response.data, response.headers['content-type'], true);
    });
}
