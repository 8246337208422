export default function download(filename, text, type, alreadyBinary) {
  let blob;
  if (alreadyBinary) {
    blob = new Blob([text], { type: type });
  } else {
    let binary = atob(text.replace(/\s/g, ''));

    let bufffer = new Buffer(binary, 'binary');
    let len = binary.length;
    let buffer = new ArrayBuffer(len);
    let view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    blob = new Blob([bufffer], { type: type });
  }

  let url = URL.createObjectURL(blob);

  let element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
